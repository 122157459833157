export const key = 'account'

export const setTenant = accountId => {
  if (!window || !window.localStorage) return
  window.localStorage.setItem(key, accountId)
}

export const unsetTenant = () => {
  if (!window || !window.localStorage) return
  window.localStorage.removeItem(key)
}

export const getTenant = () => {
  if (!window || !window.localStorage) return
  return window.localStorage.getItem(key)
}
