const {
  setTenant
} = require('../../../../components/admin/account/lib/account-switch-handler')

module.exports = function performLogin(data) {
  window.localStorage.setItem('apiKey', data.key)
  window.localStorage.setItem('apiId', data.id)
  window.localStorage.setItem('aclRoles', data.roles)
  window.localStorage.setItem('firstName', data.firstName)

  setTenant(data.account || '')

  document.location = '/'
}
